import React, {useEffect, useState} from "react";
import './index.less'
import {ISelect} from "@/components/Select/helper";

export interface CheckboxProps {
    items?: ISelect[]
    value?: any
    onChange?: any
    disabled?: boolean
}

const ZCheckbox = (props: CheckboxProps) => {
    const {items = [], value, onChange, disabled = false} = props
    const [val, setVal] = useState()
    useEffect(() => {
        setVal(value)
    }, [value])


    const onCheck = (e: any) => {
        if (disabled) {
            return
        }
        const result = val ? undefined : e
        setVal(result)
        onChange?.(result)
    }
    // 获取class
    const getClass = (i: ISelect) => {
        if (val === i.value) {
            if (disabled) {
                return 'zy-checkbox-disabled zy-checkbox-checked'
            } else {
                return 'zy-checkbox-checked'
            }
        } else {
            if (disabled) {
                return 'zy-checkbox-disabled zy-checkbox'
            } else {
                return 'zy-checkbox'
            }
        }

    }
    return (
        <>
            {items && items.map((i: ISelect, index: number) =>
                <div className='zy-checkbox-item' key={index} onClick={() => onCheck(i?.value)}>
                    <div className={getClass(i)}/>
                    <span className='bolder ml5'>{i.label}</span>
                </div>
            )}
        </>
    )
}

export default ZCheckbox